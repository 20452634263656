<template>
  <div class="sing-up-options">
    <PageBanner
      :firstword="'Welcome to'"
      :keyword="'JAMA Security'"
      :secondword="'Bug Bonty Platform.'"
      :img="'Image-Hero-JAMA-1.jpg'"
      :subtext="'La plataforma #1 en Mexico.'"
    />
    <div class="sing-up-options-cards">
      <div class="cards">
        <button @click="singupView('/singup/company')" class="card">
          <img src="../assets/img/company-logo.png" alt="" />
          <h4>Empresa</h4>
          <span>Me gustaria inscribir a mi empresa</span>
        </button>
        <button @click="singupView('/singup/investigator')" class="card">
          <img src="../assets/img/laptop-bug.svg" alt="" />
          <h4>Investigador</h4>
          <span>Me gustaria encontrar vulnerabilidades</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import PageBanner from "@/components/page-banners.vue";
export default {
  name: "SingUpOptions",
  components: { PageBanner },
  methods:{
    singupView(id){
      this.$router.push({path: id})
    }
  }
};
</script>

<style lang="scss">
.sing-up-options {
  .sing-up-options-cards {
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(180deg, #f9f9ff 0%, #f9f9ff 47%, #efeff2 100%);
    .cards {
      padding: 60px 0;
      width: 1280px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      .card {
        width: 30%;
        height: 100%;
        padding: 40px;
        background: white;
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        border: 1px solid #e9e9e9;
        cursor: pointer;
        font-family: Poppins;
        img {
          width: 100px;
        }
        span{
            margin: 0;
            width: 200px;
            text-align: center;
            font-size: 15px;
        }
        &:hover{
            box-shadow: 0px 6px 16px rgba(0, 7, 54, 0.2);
        }
      }
    }
  }
}
</style>
